import SvgWrapper from "./wrapper";

export default function EmailSVG() {
    return (
            <div>
                <SvgWrapper>

                    <div style={{width:"31px", height:"31px"}}>
                        <svg fill="white" width="31px" height="31px" x="0px" y="0px" viewBox="0 0 1000 1000" >
                            <g><path d="M500,10C229.4,10,10,229.4,10,500c0,270.6,219.4,490,490,490c270.6,0,490-219.4,490-490C990,229.4,770.6,10,500,10z M307.6,346.9h383.8c20.4,0,9.9,26.1-1.2,32.8c-11.1,6.7-164.4,99.4-170.1,102.8c-5.7,3.4-13.1,5.1-20.6,5.1c-7.4,0-14.8-1.6-20.6-5.1c-5.7-3.4-159.1-96.1-170.1-102.8C297.8,373,287.3,346.9,307.6,346.9z M704.2,627.6c0,10.7-12.8,25.5-22.7,25.5h-363c-9.8,0-22.7-14.8-22.7-25.5c0,0,0-181.4,0-186.1c0-4.7-0.1-10.8,8.8-5.6c12.6,7.4,166.7,98.3,174.4,102.8c7.7,4.5,13.1,5.1,20.6,5.1c7.4,0,12.8-0.5,20.6-5.1c7.7-4.5,162.7-95.4,175.3-102.8c8.9-5.2,8.8,0.9,8.8,5.6C704.2,446.2,704.2,627.6,704.2,627.6z"/></g>
                        </svg>
                    </div>
                </SvgWrapper>
            </div>
            )
}