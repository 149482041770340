import {OverlayTrigger, Tooltip} from "react-bootstrap";

export default function ToolTipWrapper({children, text, link}) {
    return (
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{text}</Tooltip>}>
                <span onClick={()=>{
                    window.open(link)
                }} className="d-inline-block">

                        {children}
                </span>
            </OverlayTrigger>
    )
}