
export default function LinkSVG() {
    return (
            <div>
                    <div style={{width:"31px", height:"31px"}}>
                        <svg  width="19.134" height="19" viewBox="0 0 19.134 19">
                          <path id="open_in_new_FILL0_wght400_GRAD0_opsz48" d="M7.342,23.477A1.376,1.376,0,0,1,6,22.135V8.716A1.376,1.376,0,0,1,7.342,7.374h6.24V8.716H7.342V22.135H20.76V15.9H22.1v6.24a1.376,1.376,0,0,1-1.342,1.342Zm7.549-7.8-.939-.962,8.9-8.9h-4.9V4.477h7.179v7.179H23.792V6.78Z" transform="translate(-6 -4.477)" fill="#fff" opacity="0.37"/>
                        </svg>

                    </div>
            </div>

            )
}