import styles from "./project.module.css"
import GithubMiniSVG from "../svgs/githubMini";
import LinkSVG from "../svgs/link";

export default function Project({title, description, link}){
    return (
            <div onClick={()=>{
                window.open(link)
            }}>

                <div id={styles.cont}>
                    <p id={styles.title}>{title}</p>
                    <p id={styles.description}>{description}</p>
                    <hr />

                    <div id={styles.foot}>
                        <GithubMiniSVG id={styles.GIT}/>
                        <p id={styles.link}>{link}</p>
                        <div style={{marginLeft:"auto", paddingLeft: "15px"}}>
                            <LinkSVG  />
                        </div>
                    </div>
                </div>
            </div>
    )
}