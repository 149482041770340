import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Container, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import GithubSVG from "./svgs/github"
import ToolTipWrapper from "./Components/ToolTipWrapper";
import EmailSVG from "./svgs/email";
import DiscordSVG from "./svgs/discord";
import {useEffect, useState} from "react";
import Project from "./Components/Project";
import { TypeAnimation } from 'react-type-animation';

function App() {
    var [disBar, setDis] = useState("Copy Discord ID")
    var [jsonData, setJsonData] = useState(null);

    function disCopy(e){
        setDis("Copied to Clipboard!")
        navigator.clipboard.writeText("nozhdar");
    }
    function disHover(e){
        setDis("Copy Discord ID")
    }
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch('/data.json'); // Path relative to the public folder
            const data = await response.json();
            setJsonData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, []);
  return jsonData ? (
    <div className="App">
        <Container id="cont" >
            <Row>
                <Col className="col-xl-6 col-12 d-flex flex-column justify-content-xl-center "
                        id="firstCol">
                    <div id="leftFrame">
                        <p id="title">NOZHDAR (CodeOfDark)</p>
                        <p id="subTitle">
                            <TypeAnimation
                                sequence={[
                                    'Full-Stack Developer',
                                    2000,
                                    'Full-Stack Developer & Reverse Engineer',
                                    2000,
                                    '',
                                    1,
                                ]}
                                cursor={true}
                                repeat={Infinity}
                            />
                        </p>
                        <p id="description">
                            .NET/Java/Python/Dart/PHP/ReactJS.
                            <br /><br />
                            I play CTF's from time to time and i go by the name {'<Nozhdar>'}, I also like to learn new stuff.
                            <br /><br />
                            <p style={{fontWeight: "bold"}}>My key achievements</p>
                            {
                                jsonData.achievements.map((achievement) =>{
                                    return (
                                        <>
                                            <br />
                                            - {achievement.title} <a href={achievement.link} target="_blank" rel="noreferrer">{achievement.name}</a>
                                            
                                        </>
                                    )
                                })
                            }
                            <br />
                            <br />
                            Want to get in touch? You can email me or message me on Discord.
                        </p>

                        <div id="contacts" className="d-flex justify-content-center justify-content-md-start">
                            <ToolTipWrapper text="Open Github" link="https://www.github.com/CodeOfDark">
                                <GithubSVG />
                            </ToolTipWrapper>
                            <ToolTipWrapper text="Open Email" link="mailto:support@nozhdar.com">
                                <EmailSVG />
                            </ToolTipWrapper>
                            
                            <div onClick={disCopy} onMouseLeave={disHover}>
                                <OverlayTrigger key={disBar} overlay={<Tooltip>{disBar}</Tooltip>}>
                                    <span className="d-inline-block">
                                        <DiscordSVG className="d-inline-block"/>
                                    </span>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="col-xl-6 col-12 d-flex justify-content-center" id="secondCol">
                    {
                        jsonData.projects.map((project) =>{
                            return ( <div style={{marginBottom:"32px"}}>
                                <Project title={project.title} link={project.link} description={project.description} />
                            </div>)
                        })
                    }
              </Col>
          </Row>
        </Container>
    </div>
  ) : <></>;
}

export default App;
