import styles from "./discord.module.css"


export default function SvgWrapper({children}) {
    return (
            <div style={{width:"45px",
                height:"45px",
                display:"flex",
                justifyContent:"center",
                alignItems:"center",

                
                cursor: "pointer"
            }}
                id={styles.test}
                >
                {children}
            </div>
       )
}